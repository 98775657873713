import React from "react"
import { Link } from "gatsby"

import { Quote1, Quote2, Quote3} from "./Quotes"

function Press(){
    return(
        <section className="press">
            <div className="title">
                <h1>DreamCraft Closes $10 Million Series A Funding Led by March Gaming</h1>
                <div className="date">November 17, 2021</div>
            </div>
            <section>
                <p>
                    DreamCraft is excited to announce that we have raised $10 million in Series A funding led by March Gaming (Gregory Milken and Louis Gresham) to accelerate our growth as we enable anyone to create, publish and monetize video games. The new funding brings our total money raised to over $15 million and will allow us to bring the game development opportunity to a broader base of creative individuals, build new IP with talented artists, and scale a top-notch engineering, art, and game design team.
                </p>
                <p>
                    Investors in the round also include Makers Fund (Jay Chi and Ryann Lai), Dune Ventures (David Brillembourg Jr.) and Hiro Capital (Luke Alvarez and Cherry Freeman), and of course we wouldn’t have made it this far without our incredible seed investors, including Tencent, Y Combinator, Makers Fund, Ludlow Ventures, Kevin Lin (Co-Founder of Twitch) and Hubert Thieblot (previously of Curse and Twitch). We’re grateful to have such great friends support us on our journey to disrupt game-making technology. 
                </p>
            </section>
            <section>
                <h4>
                    What Is DreamCraft
                </h4>
                <p>
                    DreamCraft, founded in 2018, is building a platform bundled with a game editor to make the game development process significantly easier for any platform, especially for people who have a passion for building games but lack programming or art skills. 
                </p>
                <p>
                    Founded by a team of passionate gamers and modders, we wanted to find a solution for individual developers with no professional game development or programming experience to be able to create a game in just a few months and potentially generate millions of organic downloads and millions of dollars in revenue. To say this is a disruptive proposition in the gaming industry is an understatement, as commercially successful games typically take a team of professional developers years to build. 
                </p>
            </section>
            <section>
                <h4>
                    How DreamCraft Works
                </h4>
                <p>
                    DreamCraft’s game editor offers a high-level, event-driven framework that includes features such as multiplayer, cross-platform, performance, memory-usage, and publishing that anyone can build games on top of. In other words, creators can focus solely on building unique gameplay mechanics for any genre and let DreamCraft do all the other heavy lifting. We believe this is the key to enable success for individual game creators.
                </p>
                <p>
                    DreamCraft also offers a wide range of art assets with a vision to build a cohesive world and IP, including casual and cartoon-like art styles that appeal to a broad base of young audiences. In addition, our technology empowers both 3D and 2D pre-rendered visuals, so creators have great design freedom for different genres of games.
                </p>
                <img src="https://i.imgur.com/bb2nnCk.png" alt="© DreamCraft Entertainment" />
                <p>
                    To date, we’ve seen games that were created by a solo developer and powered by the DreamCraft game editor generate millions of organic downloads and millions of dollars in revenue. Other games are in various stages, from development through initial testing, soft launching and early monetization testing. These games are all created by one-man shops.  
                </p>
            </section>
            <section>
                <h4>
                    Our Diverse Team
                </h4>
                <p>
                    We are lifelong, passionate gamers who founded DreamCraft in 2018 with a vision to serve those who want to create video games as a life-long career. Since then, we have grown into a global team of 20+ people, who are deeply rooted in various modding communities and have worked at companies like Google, Blizzard, Microsoft and EA. 
                </p>
                <p>
                Our diverse team spans North America, South America, Asia and Europe, and we are always searching for talented people to join DreamCraft on our exciting journey. If you’re interested, check out our <Link to="../careers" className="press">career page</Link> for more details.
                </p>
            </section>
            <section>
                <h4>
                    Investor Quotes
                </h4>
                {Quote1}
                {Quote2}
                {Quote3}
            </section>
            <section>
                <h4>
                    Contacts
                </h4>
                <p>
                    Tianyin Zhang <br />
                    Veronica Yao <br />
                    <a href="mailto:founders@dreamcraft.com" className="press">founders@dreamcraft.com</a>
                </p>
            </section>
        </section>
    )
}

export default Press