import React from "react"

import Landing from "./Landing"
import Press from "./Press" 

function Home(){
    return(
        <main>
            <Landing />
            <Press />
        </main>
    )
}

export default Home