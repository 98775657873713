import React from "react"

// Separated cos originally wanted to re-use some of the quotes. Might come in handy in the future.

export const Quote1 = <article className="quote">
    <blockquote>
        DreamCraft is exactly what it sounds like: empowering bright creators to realize their game making visions and providing an easy way for them to build and publish their games and make money. We’ve seen firsthand the magic that happens when hobbyists get their hands on what the DreamCraft team has built and realize they can fully develop and monetize a dynamic, high-quality game in mere months with exponentially less friction than any UGC platform or engine out there. We are thrilled to support Tianyin, Veronica and the DreamCraft team with their mission to help every modder and map editor out there dream even bigger.
    </blockquote>
    <div className="name">Louis Gresham, March Gaming</div>
</article>

export const Quote2 = <article className="quote">
    <blockquote>
        DreamCraft’s founders bring the passion and mindset of modders to a powerful platform that does all the heavy lifting so design and creativity can be free of complex tools and giant development teams. We are excited to support DreamCraft in enabling dreamers to become game makers, and to help open the doors to a creator-led game economy.
    </blockquote>
    <div className="name">Ryann Lai, Makers Fund</div>
</article>

export const Quote3 = <article className="quote">
    <blockquote>
        We are ecstatic to be backing Tianyin and Veronica – they have relentlessly worked to get DreamCraft in the hands of creators and we’re already seeing some amazing games come to life. Few teams and platforms in UGC have had this much impact this early on, partly due to the fact that the team is composed of passionate gamers and modders, so we’re looking forward to seeing this team and platform scale.
    </blockquote>
    <div className="name">David Brillembourg Jr., Dune Ventures</div>
</article>