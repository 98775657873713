import React from "react"
import { Link } from "gatsby"

function Landing(){
    return (
        <section className="landing">
            <img src="https://i.imgur.com/y4eepnj.png" alt="© DreamCraft Entertainment" />
            <h3 className="tagline">Democratize Game Development</h3>
            <p>
                A platform that greatly simplifies game development, especially for people who have a passion for building games but lack programming or art skills.
            </p>
            <Link to={'/careers'}>
                <button>
                    Join Us
                </button>
            </Link>
        </section>
    )
}

export default Landing