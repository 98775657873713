import React, { useState } from 'react';
import Nav from "../components/Nav"
import Home from "../components/home/Home"
import Footer from '../components/Footer'
import SEO from '../components/SEO'

function App() {
    const [sidebarOn, setSidebar] = useState(false)
    const responsiveSetSidebar = () =>{
        if (typeof window !== `undefined`) {
            if (window.innerWidth < 770)
                setSidebar(!sidebarOn)
        }
    }
    return (
        <>
            <SEO title="DreamCraft Home Page" description="A platform that greatly simplifies game development, especially for people who have a passion for building games but lack programming or art skills." keywords="dreamcraft, video games, ugc, game editor, code free"/>
            <Nav sidebarOn={sidebarOn} setSidebar={responsiveSetSidebar}/>
            <div className={`App ${sidebarOn ? "open" : "closed"}`} onClick={() => { if (sidebarOn) setSidebar(); }}>
                <Home />
                <Footer />
            </div>
        </>
    );
}

export default App;
